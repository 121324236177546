import { Injectable } from '@angular/core';
import { CommonRepositoryAbstract, FirebaseUtils } from '@freddy/common';
import { Store } from '@ngxs/store';
import { InGameState } from '../stores/in-game.store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseChatMessage } from '@freddy/models';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class ChatMessageRepository extends CommonRepositoryAbstract<FirebaseChatMessage> {
  constructor(
    firestore: Firestore,
    private readonly store: Store,
  ) {
    super(firestore);
  }

  public getMessages(chatUid: string): Observable<FirebaseChatMessage[]> {
    return this.getCollectionsChanges({
      chatUid,
    }).pipe(
      map((messages) =>
        messages.map((message) => FirebaseUtils.convertDate(message)),
      ),
      map((messages) =>
        messages.sort((a, b) => {
          // @ts-ignore
          return a.createdAt - b.createdAt;
        }),
      ),
    );
  }

  protected getDocPath(): string {
    return `${this.store.selectSnapshot(
      InGameState.gamePath,
    )}/chats/:chatUid/messages`;
  }
}
